<template>
    <Btn :loading="isLoading" @click.native="onButtonClick" class="btn" :class="classes" :style="styles" :icon="icon">
        <slot />
    </Btn>
</template>

<script>
import Btn from "./Btn.vue"
import { runScopeFn } from "@Platon/core/condition"

export default {
    name: "ActionBtn",

    props: {
        classes: {
            default: "btn-primary"
        },
        styles: {},
        icon: {},
        js: {},
        jsContext: {}
    },

    components: { Btn },

    data() {
        return {
            isLoading: false
        }
    },

    methods: {
        onButtonClick() {
            if (this.js) {
                if (typeof this.js === "function") {
                    this.js(this.jsContext, this)
                } else {
                    let args = { button: this }

                    if (typeof this.jsContext.runScopeArgs === "function") {
                        let scopeArgs = this.jsContext.runScopeArgs()

                        if (typeof scopeArgs === "object" && scopeArgs) {
                            args = Object.assign(args, scopeArgs)
                        }
                    }

                    runScopeFn(this.js, this.jsContext, args)
                }
            }
        }
    }
}
</script>

<style></style>
